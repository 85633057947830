import React, { useEffect } from 'react';
import '../assets/styles/loader.css';

export default function Loader() {

    return (
    <div className='bg-cream w-screen h-screen'>

      <div className="loader w-screen h-screen flex flex-col items-center justify-center relative" data-aos="fade-left">
        <svg
          id="loader"
          width="800"
          height="148"
          viewBox="0 0 866 148"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.48438 104.5V39.6562C5.65625 30.4115 9.6276 22.2734 16.3984 15.2422C23.5599 7.95052 32.349 4.30469 42.7656 4.30469V31.8438C37.1667 31.974 33.7161 34.6432 32.4141 39.8516C32.1536 41.0234 32.0234 42 32.0234 42.7812V104.5C32.1536 109.839 35.1484 113.354 41.0078 115.047C41.7891 115.307 42.375 115.438 42.7656 115.438V142.977C35.2135 142.977 27.6615 140.307 20.1094 134.969C9.82292 127.417 4.61458 117.26 4.48438 104.5ZM53.3125 142.977V115.438C54.7448 115.438 56.5677 114.266 58.7812 111.922C60.7344 109.708 61.7109 107.365 61.7109 104.891V98.4453H53.3125V73.0547H89.4453V107.625C89.4453 115.698 85.2786 123.641 76.9453 131.453C69.0026 139.005 61.125 142.846 53.3125 142.977ZM53.3125 31.8438V4.30469C62.9479 4.30469 71.5417 5.47135 79.0938 16.8047C85.9948 24.2266 89.4453 32.3646 89.4453 41.2188L62.4922 51.1797V43.5625C62.4922 37.4427 60.1484 33.6667 55.4609 32.2344C54.6797 31.974 53.9635 31.8438 53.3125 31.8438Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M104.289 142V5.28125H131.828V142H104.289ZM142.375 86.1406V61.7266H144.914C153.247 61.7266 158.586 58.3411 160.93 51.5703C161.451 49.8776 161.711 48.1198 161.711 46.2969C161.711 39.3958 158.456 34.7734 151.945 32.4297C149.862 31.6484 147.518 31.2578 144.914 31.2578H142.375V5.28125H146.672C163.729 5.54167 175.708 11.5964 182.609 23.4453C185.865 29.0443 187.492 35.3594 187.492 42.3906C187.362 55.8021 182.935 66.0885 174.211 73.25C180.721 77.4167 184.888 84.9688 186.711 95.9062C186.841 97.2083 186.971 98.4453 187.102 99.6172L190.422 142H165.031L162.102 107.234C161.06 96.8177 158.065 90.3724 153.117 87.8984C150.773 86.8568 147.909 86.2708 144.523 86.1406H142.375Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M202.141 103.914V39.6562C202.141 32.7552 205.07 25.724 210.93 18.5625C218.742 9.1875 228.638 4.4349 240.617 4.30469V31.8438C235.279 31.974 231.763 34.9688 230.07 40.8281C229.81 41.7396 229.68 42.3906 229.68 42.7812V103.914C229.68 109.253 232.609 112.898 238.469 114.852C239.38 115.112 240.031 115.242 240.422 115.242V142.977C233 142.977 225.578 140.307 218.156 134.969C207.609 127.156 202.271 116.805 202.141 103.914ZM251.164 142.977V115.242C253.247 115.242 255.591 114.135 258.195 111.922C260.669 109.708 261.971 107.039 262.102 103.914V42.7812C262.102 40.4375 260.995 37.9635 258.781 35.3594C256.698 33.1458 254.159 31.974 251.164 31.8438V4.30469C264.315 4.30469 274.862 9.96875 282.805 21.2969C287.362 27.8073 289.641 33.9271 289.641 39.6562V103.914C289.641 118.367 283.195 129.435 270.305 137.117C263.534 141.023 257.154 142.977 251.164 142.977Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M299.016 5.28125L338.664 142H347.844L358.195 111.141L327.141 5.28125H299.016ZM349.211 41.6094L362.102 85.3594H364.055C364.966 82.3646 366.333 77.8724 368.156 71.8828C370.63 63.4193 372.518 57.1042 373.82 52.9375L399.602 142H408.781L418.938 111.141L388.078 5.28125H359.953L349.211 41.6094ZM410.93 41.6094L423.82 85.3594H425.773L449.797 5.28125H421.672L410.93 41.6094Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M501.555 32.2344V5.28125H586.125V32.2344H557.609V142H530.07V32.2344H501.555Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M597.258 142V5.28125H668.352V32.625H624.797V60.3594H649.992V87.8984H624.797V114.461H668.352V142H597.258Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M683.195 106.648V40.8281C683.195 33.276 685.995 25.9193 691.594 18.7578C699.016 9.2526 709.042 4.4349 721.672 4.30469V31.8438C716.203 31.974 712.688 34.513 711.125 39.4609C710.865 39.9818 710.734 40.4375 710.734 40.8281V106.648C710.734 108.732 711.841 110.75 714.055 112.703C716.138 114.526 718.677 115.438 721.672 115.438V142.977C707.089 142.977 696.086 136.857 688.664 124.617C685.018 118.367 683.195 112.378 683.195 106.648ZM732.219 142.977V115.438C732.219 115.438 733.716 114.852 736.711 113.68C738.534 112.378 739.771 111.01 740.422 109.578C741.073 107.885 741.398 105.477 741.398 102.352V100.008L768.352 106.453C768.352 117.781 763.794 127.156 754.68 134.578C747.779 140.177 740.292 142.977 732.219 142.977ZM732.219 31.8438V4.30469C741.724 4.30469 750.318 5.47135 758 16.8047C764.901 24.3568 768.352 32.4948 768.352 41.2188L741.398 48.25V45.3203C741.398 42.0651 741.073 39.6562 740.422 38.0938C739.901 36.7917 738.924 35.6198 737.492 34.5781C736.19 33.5365 735.018 32.8203 733.977 32.4297L732.219 31.8438Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
          <path
            d="M780.07 142V5.28125H807.609V59.7734H815.227V86.7266H807.609V142H780.07ZM825.969 86.7266V59.7734H833.586V5.28125H861.125V142H833.586V86.7266H825.969Z"
            stroke="black"
            stroke-width="5"
            mask="url(#path-1-outside-1)"
          />
        </svg>
      </div>
    </div>
  );
}
